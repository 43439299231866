import request from '@/utils/request';

// 模糊搜索
export function vague(data) {
  return request({
    url: '/goods/vague',
    method: 'post',
    data
  });
}

// 关键字搜索
export function keyword(data) {
  return request({
    url: '/search/keyword',
    method: 'post',
    data
  });
}
