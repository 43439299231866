<template>
  <div id="search">
    <!-- 顶部 -->
    <van-row align="center" type="flex" justify="space-around">
      <!-- 导航栏 -->
      <van-col span="2">
        <van-nav-bar left-arrow @click-left="onClickLeft"></van-nav-bar>
      </van-col>

      <!-- 搜索栏 -->
      <van-col span="22">
        <van-search shape="round" v-model="hotsearch" show-action placeholder="请输入搜索关键词">
          <template #action>
            <van-button @click="searchFn(hotsearch)" type="default" round size="small" color="#000">搜索</van-button>
          </template>
        </van-search>
      </van-col>
    </van-row>

    <!-- 新商品 -->
    <div class="goodslist" v-if="product.length > 0">
      <van-row justify="space-around" :gutter="15">
        <van-col span="12" @click="fn(item.id)" v-for="item in product" :key="item.id">
          <van-image width="160" height="160" fit="cover" :src="host + item.defaultPic">
            <template v-slot:loading>
              <van-loading vertical type="spinner" size="20" />
            </template>
          </van-image>
          <span class="Name">{{ item.name }}</span>
          <p>
            <span>¥{{ item.price }}</span>
            <span>月销量{{ item.purchaseNums }}</span>
          </p>
        </van-col>
      </van-row>
    </div>

    <!-- 关键词 -->
    <main v-else class="hot">
      <p>热门推荐</p>
      <van-row class="keyword" type="flex" justify="space-start" gutter="0">
        <van-col span="6" v-for="item in keywordlist" :key="item.id">
          <van-button round color="#f2f2f2" class="btn" size="small" @click="key(item.keyword)">
            {{ item.keyword }}
          </van-button>
        </van-col>
      </van-row>
    </main>
  </div>
</template>

<script>
import { vague, keyword } from '@/api/search';
export default {
  nanme: 'Search',
  data() {
    return {
      hotsearch: '',
      product: [], //商品数据
      keywordlist: [], //关键字数据
      host: 'https://img.muji.com.cn/img/item/'
    };
  },
  created() {
    // 操作父组件App数据 子组件修改父组件 隐藏tabbar
    this.$parent.showMenu = false;
    //获取Home组件传递过来的query参数
    const { hotsearch } = this.$route.query;
    this.hotsearch = hotsearch;
    // 关键字请求
    this.getKeyword();
  },
  methods: {
    async getSearch(keyword) {
      try {
        const {
          data: { result }
        } = await vague({ value: keyword, size: 20 });
        this.product = result;
      } catch (error) {
        console.log(error);
      }
    },
    async getKeyword() {
      try {
        const {
          data: { result }
        } = await keyword({});
        this.keywordlist = result;
      } catch (error) {
        console.log(error);
      }
    },
    onClickLeft() {
      this.$router.back(-1);
    },
    searchFn(keyword) {
      this.getSearch(keyword);
    },
    // 热词搜索
    key(keyword) {
      this.hotsearch = keyword;
      this.getSearch(keyword);
    }
  },
  beforeRouteLeave(to, from, next) {
    // 在导航离开渲染该组件的对应路由时调用
    // 与 `beforeRouteUpdate` 一样，它可以访问组件实例 `this`
    this.$parent.showMenu = true;
    next();
  }
};
</script>

<style scoped lang="scss">
#search {
  padding: 10px;

  .goodslist {
    padding: 10px;

    span {
      font-size: 14px;
      text-align: left;
    }

    p {
      span {
        &:nth-of-type(1) {
          margin-right: 5px;
          color: red;
        }

        &:nth-of-type(2) {
          float: right;
          color: #999;
        }
      }
    }

    .Name {
      display: block;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }

  .hot {
    p {
      font-size: 14px;
      line-height: 18px;
    }

    .keyword {
      text-align: center;

      .btn {
        margin-top: 15px;
        color: #000 !important;
      }
    }
  }
}
</style>